<template>
  <div class="test">
    <my-header title="考试考核"></my-header>
    <!-- 考试考核页面 -->
    <div class="title">
      <div v-for="(item, index) in titleList" :key="index">
        <div class="info" @click="switchList(index)">
          <p :class="index == selectTitle ? 'selectNum' : 'num'">
            {{ item.num }}
          </p>
          <p class="name">{{ item.label }}</p>
        </div>
        <div v-if="index != titleList.length - 1" class="divider"></div>
      </div>
    </div>
    <div class="body">
      <p class="body-title">{{ bodyTitle }}</p>
      <div v-for="(item, index) in showList" :key="index" class="list">
        <p class="no">{{ index + 1 }}.</p>
        <p class="list-title">{{ item.examTitle }}</p>
        <p class="time">考试时间：{{ item.time }}</p>
        <div class="tag-pass" v-if="item.userExamStatus == 5">已过期</div>
        <div class="tag" v-if="item.userExamStatus == 3">评分中</div>
        <p class="create">考试发布人：{{ item.publishBy }}</p>
        <p class="length">考试时长：{{ item.duration / 60 }}分钟</p>
        <div class="button">
          <img
            src="@/assets/image/assessBtn1.png"
            v-if="item.userExamStatus == 2"
          />
          <p
            class="p1"
            @click="participate(item)"
            v-if="item.userExamStatus == 2"
          >
            参加考试
          </p>

          <p class="p2" v-if="item.userExamStatus == 1">未开始</p>
          <img
            src="@/assets/image/assessBtn2.png"
            v-if="item.userExamStatus == 1"
          />

          <p
            class="p3"
            v-if="item.userExamStatus == 4"
            @click="viewResults(item)"
          >
            查看结果
          </p>
          <img
            src="@/assets/image/assessBtn3.png"
            v-if="item.userExamStatus == 4"
          />
        </div>
      </div>
      <van-button
        class="body-button"
        type="default"
        color="linear-gradient(90deg, #3DBCE0, #0DA5EC)"
        @click="goWrong"
        >错题回顾</van-button
      >
     <!--  <van-button
        class="body-button"
        type="default"
        color="linear-gradient(90deg, #FB7A08, #FE6113)"
        style="margin: 20px 0 40px 0"
        >题库练习</van-button
      > -->
    </div>
    <van-overlay :show="show">
      <div class="modal">
        <div class="center">
          <img class="bg" src="@/assets/image/my/modalBg.png" />
          <img class="top" src="@/assets/image/my/modalImg.png" />
          <p class="center-title">{{ form.examTitle }}</p>
          <div class="center-body">
            <p>
              <span v-if="form.startTime && form.endTime"
                >考试时间:
                {{ form.startTime.split(' ')[0].split('-').join('/') }}-{{
                  form.endTime.split(' ')[0].split('-').join('/')
                }}</span
              >
            </p>
            <p>
              <span>考试机构: {{ form.examOrg }}</span>
            </p>
            <p>
              <span>答题时长: {{ form.duration / 60 }}分钟</span>
              <span style="position: absolute; left: 43.125rem"
                >考试题数: {{ form.totalCount }}题</span
              >
            </p>
            <p></p>
            <p>考试说明:</p>
          </div>
          <div class="instructions">
            <p>{{ form.remark }}</p>
            <!-- <p>
              共分为4个，单选题（20分）、多选题（40分）、判断题（10分）、问答题（30分）。
            </p> -->
          </div>
          <van-button
            class="button"
            type="default"
            color="linear-gradient(90deg, #FB7A08, #FE6113)"
            @click="startTest"
            >开始考试</van-button
          >
        </div>
        <img
          class="close"
          @click="close"
          src="@/assets/image/my/modalClose.png"
        />
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { getTestList, getTestInfo } from '@/api/my/test'
export default {
  data() {
    return {
      titleList: {
        totalCount: { label: '全部', num: '' },
        noStartCount: { label: '未开始', num: '' },
        startedCount: { label: '已开始', num: '' },
        expiredCount: { label: '过期', num: '' },
        markingCount: { label: '评分中', num: '' },
        resultCount: { label: '出成绩', num: '' },
      },
      selectTitle: 'totalCount',
      list: [],
      show: false,
      form: {}, //弹窗数据
      examId: '', //点击考试的id
    }
  },
  created() {
    getTestList(this.$store.getters.user.id).then((res) => {
      const {
        examList,
        totalCount,
        noStartCount,
        startedCount,
        expiredCount,
        markingCount,
        resultCount,
      } = res.result
      this.list = examList
      this.titleList.totalCount.num = totalCount
      this.titleList.noStartCount.num = noStartCount
      this.titleList.startedCount.num = startedCount
      this.titleList.expiredCount.num = expiredCount
      this.titleList.markingCount.num = markingCount
      this.titleList.resultCount.num = resultCount
    })
  },
  computed: {
    bodyTitle() {
      return this.titleList[this.selectTitle].label
    },
    showList() {
      let arr = this.list.filter((item) => {
        if (this.selectTitle == 'totalCount') {
          return item
        } else if (
          this.selectTitle == 'noStartCount' &&
          item.userExamStatus == 1
        ) {
          return item
        } else if (
          this.selectTitle == 'startedCount' &&
          item.userExamStatus == 2
        ) {
          return item
        } else if (
          this.selectTitle == 'expiredCount' &&
          item.userExamStatus == 5
        ) {
          return item
        } else if (
          this.selectTitle == 'markingCount' &&
          item.userExamStatus == 3
        ) {
          return item
        } else if (
          this.selectTitle == 'resultCount' &&
          item.userExamStatus == 4
        ) {
          return item
        }
      })
      for (const item of arr) {
        item.time =
          item.startTime.split(' ')[0].split('-').join('/') +
          '-' +
          item.endTime.split(' ')[0].split('-').join('/')
      }
      return arr
    },
  },
  methods: {
    goWrong() {
      this.$router.push({ path: '/test/wrong' })
    },
    // 参加考试
    participate(item) {
      this.examId = item.examId
      getTestInfo(this.$store.getters.user.id, item.examId).then((res) => {
        this.form = res.result
      })
      this.show = true
    },
    startTest() {
      this.$router.push({ path: '/test/start', query: { examId: this.examId } })
    },
    // 查看考试结果
    viewResults(item) {
      this.$router.push({ path: '/test/result', query: { id: item.examId } })
    },
    close() {
      this.show = false
    },
    switchList(query) {
      this.selectTitle = query
    },
  },
}
</script>
<style lang="less" scoped>
.test {
  margin-top: 64px;
}
.title {
  display: flex;
  justify-content: space-around;
  padding: 0 4rem;
  div {
    display: inline-block;
    vertical-align: bottom;
  }
  .info {
    text-align: center;
    .num {
      color: #000000;
      font-size: 3.75rem;
      font-weight: bold;
    }
    .selectNum {
      color: #ea5514;
      font-size: 3.75rem;
      font-weight: bold;
    }
    .name {
      color: #666666;
      font-weight: 400;
      font-size: 2.5rem;
    }
  }
  .divider {
    height: 48px;
    width: 2px;
    margin-left: 2.5rem;
    background-color: #b5b5b6;
  }
}
.body {
  width: 100%;
  padding: 0 4rem;
  &-title {
    margin: 85px 0 8px 0;
    font-size: 3rem;
    font-weight: 400;
  }
  .list {
    width: 100%;
    box-shadow: 2px 2px 9px 1px rgba(3, 3, 3, 0.05),
      -2px -2px 9px 1px rgba(3, 3, 3, 0.05);
    border-radius: 32px;
    margin-top: 40px;
    position: relative;
    padding: 48px 8rem 80px 6rem;
    font-weight: 400;

    .no {
      float: left;
      margin-left: -3.5rem;
      font-size: 3.375rem;
      font-weight: 350;
      margin-top: 4px;
    }
    &-title {
      font-size: 3.375rem;
      font-family: Source Han Sans CN;
      font-weight: 350;
    }
    .time {
      margin-top: 40px;
      font-size: 2.5rem;
      color: @font-gray-1;
      display: inline-block;
    }
    .tag {
      display: inline-block;
      border: 1px solid #ea5514;
      border-radius: 5px;
      font-size: 2rem;
      font-weight: 400;
      color: #ea5514;
      margin-left: 2.5rem;
      padding: 0.5rem 1rem;
    }
    .tag-pass {
      display: inline-block;
      // width: 7.75rem;
      // height: 3.375rem;
      background: #b5b5b6;
      border-radius: 5px;
      font-size: 2rem;
      font-weight: 400;
      color: #ffffff;
      margin-left: 2.5rem;
      padding: 0.5rem 1rem;
    }
    .create {
      margin-top: 20px;
      font-size: 2.5rem;
      color: @font-gray-1;
    }
    .length {
      margin-top: 20px;
      font-size: 2.5rem;
      color: @font-gray-1;
    }
    .button {
      position: absolute;
      bottom: 1px;
      right: 1px;
      height: 89px;
      width: 21.125rem;
      img {
        width: 100%;
      }
      p {
        position: absolute;
        font-size: 2.5rem;
        font-weight: 400;
        z-index: 1;
        top: 20%;
        text-align: center;
      }
      .p1 {
        left: 25%;
        color: #ffffff;
      }
      .p2 {
        left: 33%;
        color: #ffffff;
      }
      .p3 {
        left: 25%;
        color: #ea5514;
      }
    }
  }
  &-button {
    width: 100%;
    font-size: 2.5rem;
    font-weight: 400;
    color: #ffffff;
    margin-top: 72px;
    border-radius: 8px;
  }
}
.modal {
  margin-top: 500px;
  position: relative;
  .center {
    position: relative;
    .bg {
      width: 72.25rem;
      position: absolute;
      top: 0;
      left: 4rem;
      z-index: 1;
    }
    .top {
      position: absolute;
      left: 13rem;
      width: 54.6875rem;
      top: -234px;
      z-index: 2;
    }
    &-title {
      position: absolute;
      top: 602px;
      left: 7rem;
      font-size: 54px;
      font-weight: 400;
      color: #ea5514;
      z-index: 2;
    }
    &-body {
      position: absolute;
      top: 692px;
      left: 7rem;
      font-size: 40px;
      font-weight: 400;
      color: #666666;
      width: 100%;
      z-index: 2;
      p {
        margin-top: 20px;
        position: relative;
      }
    }
    .instructions {
      position: absolute;
      width: 66.25rem;
      left: 7rem;
      top: 1000px;
      z-index: 2;
      border: 1px solid #b5b5b6;
      border-radius: 4px;
      font-size: 40px;
      font-weight: 400;
      color: #666666;
      padding: 42px 6rem 70px 2.625rem;
    }
    .button {
      position: absolute;
      top: 1300px;
      left: 28.75rem;
      width: 338px;
      height: 88px;
      border-radius: 44px;
      z-index: 2;
    }
  }
  .close {
    position: absolute;
    width: 82px;
    top: 1500px;
    left: 38rem;
  }
}
</style>
